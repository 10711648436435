<template>
  <div class="service page_width">
      <h5 class="title">Terms of service</h5>
      <h3 class="title_big">Platform Service Agreement</h3>
      <div class="text_msg">To protect the rights and interests of users, please carefully read all the contents of this service agreement (hereinafter referred to as "this Agreement") before logging into the platform. Pay special attention to provisions that exempt or limit the company's liability (these clauses typically contain terms like "no responsibility," "disclaimer," or "no obligation"), as well as the applicable laws, jurisdiction clauses in the event of disputes, and other terms that restrict user rights. These terms shall apply to the fullest extent permitted by PRC law and are usually highlighted in red, bold, or similar emphasized formats.</div>
      <div class="text_msg">This Agreement applies to the user's use of the services provided by the platform and carries the full legal effect of a contract. By clicking [Accept] (or similar terms such as "Agree"), registering, or using the platform's services, the user is deemed to have accepted and agreed to all the terms of this Agreement. The user shall not claim failure to read or agree to the contents of this Agreement as a defense.</div>
      <div class="text_msg">The formation, performance, interpretation, and resolution of disputes related to this Agreement shall be governed by the laws of the People's Republic of China (PRC) and all relevant conflict-of-law rules. This Agreement is entered into in Baoquanling Agricultural Reclamation, Heilongjiang Province. If any dispute arises between the parties concerning the contents or execution of this Agreement (including but not limited to contract or property rights disputes), the parties shall attempt to resolve it through friendly negotiation. If negotiations fail, the parties agree to submit the dispute to the jurisdiction of the court in the location where this Agreement was concluded.</div>

      <h3 class="title_big">Article 1: Content and Validity of the Agreement</h3>
      <div class="text_msg">1.1 This Agreement is entered into by the user and the company operating the platform (hereinafter referred to as "the Company").</div>
      <div class="text_msg">1.2 The terms outlined in this Agreement may not exhaustively cover all rights and obligations between the platform and the user. Therefore, the body of this Agreement, along with all current and future rules, guidelines, and procedures published by the platform, constitute an inseparable part of this Agreement and hold the same legal weight. By using the platform’s services, the user agrees to accept and comply with the provisions of the relevant rules.</div>
      <div class="text_msg">1.3 The Company reserves the right to modify or update the terms of this Agreement, platform rules, and other relevant content as necessary, in accordance with applicable laws and regulations. Users are responsible for promptly reviewing and understanding any updates to the terms when using the platform's services and are expected to comply with them. If the user continues to use the platform after any changes, it is deemed that the user fully accepts the modified terms. Otherwise, the user must immediately stop using the platform.</div>

      <h3 class="title_big">Article 2: Definitions</h3>
      <div class="text_msg">2.1 Platform: Refers to a third-party online trading platform that provides a venue for business transactions, matching services, and information dissemination. The platform allows parties to independently engage in trading activities.</div>
      <div class="text_msg">2.2 "Rules": Refers to all current and future rules, interpretations, announcements, and other guidelines published by the platform. This includes all forms of rules, implementation instructions, notices, Q&A, help guides, and transaction process descriptions published on the platform's forums, help center, or similar venues.</div>
      <div class="text_msg">2.3 "User": Refers to "you" or the "end user," which is defined as a natural person, legal entity, or other organization that has obtained the Company's authorization for non-commercial use of the platform software through legal means. This includes those who install and use the platform software or engage in transactions related to game accessories through the platform.</div>
      <div class="text_msg">2.4 "Game Accessories": Refers to in-game characters, resources, props, and similar items (including, but not limited to, weapons, mounts, pets, equipment, and skins) provided by the Steam platform. These accessories can be transferred between different accounts within the platform. The terms "pick-up," "booking," "creating transactions," and other similar terms used on the platform refer exclusively to the transfer of these items or their associated property interests between specific entities.</div>
      

      <h3 class="title_big">Article 3: Account Registration and Use</h3>
      <div class="text_msg">3.1 Before using the platform's services, the user must register to obtain an account and password for the platform (hereinafter collectively referred to as "Platform Account" or "User Account"). Ownership of the user account belongs to the platform. By completing the registration process, the user gains the right to use the account in accordance with this Agreement and the platform's rules.</div>
      <div class="text_msg">3.2 The user agrees and acknowledges that the platform's services are provided to individuals with full legal capacity, allowing them to independently bear civil liability under the laws of the People's Republic of China. If the user has limited civil capacity, they must review this Agreement with a legal representative and obtain consent before using the platform's services, making payments, and agreeing to the terms. Individuals without civil capacity may not use the platform's services.</div>
      <div class="text_msg">3.3 The user is required to provide true, accurate, current, and complete personal information during registration. If any information provided is false, inaccurate, incomplete, or outdated, or if the Company reasonably suspects such, it reserves the right to refuse or terminate the user's access to the platform. The user acknowledges that the personal information they provide is the only valid proof of their association with the registered platform account.</div>
      <div class="text_msg">3.4 The user understands that they are responsible for maintaining the confidentiality and security of their account and password. The user must ensure the proper and secure use of their account and will be fully liable for any actions taken using their account. If the user experiences loss or theft of their account password due to improper management, they will be held legally responsible for any resulting damages.</div>
      <div class="text_msg">3.5 If the user discovers that a third party has fraudulently used their account or password, or there is any other unauthorized activity, the user must immediately notify the platform and provide personal information that matches the registration information for the account in question.</div>
      <div class="text_msg">If such unauthorized activity occurs and the platform verifies that the provided personal information matches the registration information, the platform has the right to investigate and take necessary actions to protect the transaction's security. This may include suspending or terminating platform services, freezing orders, restricting or prohibiting account access, or other measures. The user cannot hold the platform liable for these actions. If the user's personal information does not match the registration information, the platform has the right to deny the user's request, and the platform is not responsible for any consequences arising from unauthorized account use.</div>
      <div class="text_msg">3.6 In accordance with current Chinese laws and regulations, users may be required to complete real-name authentication following platform rules.</div>
      <div class="text_msg">3.7 User accounts are non-transferable unless permitted by law, a judicial ruling, or with the platform's consent. If an account is transferred, all associated rights and obligations are transferred as well.</div>
      <div class="text_msg">3.8 If the user complies with all applicable laws and platform rules, they may request account cancellation by contacting platform customer service and following the required procedures. Once the account is canceled, the user will no longer have access to platform services or any content or information related to the account.</div>
      <div class="text_msg">3.9 If the user does not log in to the platform for 365 consecutive days, the Company reserves the right to delete any records associated with the user account after the 365th day, and such deleted data and information cannot be recovered.</div>

      <h3 class="title_big">Article 4: User Code of Conduct</h3>
      <div class="text_msg">4.1 The user agrees that all activities conducted while using the platform’s services will comply with the laws and regulations of the People’s Republic of China, as well as this Agreement and the platform’s rules.</div>
      <div class="text_msg">4.2 The user commits to adhering to the principle of good faith during transactions on the platform and shall not engage in fraudulent, deceptive, or misleading activities.</div>
      <div class="text_msg">4.3 When publishing information on the platform or using its services, the user shall not produce, copy, publish, or distribute the following types of information:</div>
      <div class="text_msg">(1) Information that violates the basic principles of the Constitution of the People’s Republic of China;</div>
      <div class="text_msg">(2) Content that endangers national security, reveals state secrets, subverts state power, or undermines national unity;</div>
      <div class="text_msg">(3) Information that harms the national honor and interests of the People’s Republic of China;</div>
      <div class="text_msg">(4) Content inciting ethnic hatred or discrimination, or undermining national unity;</div>
      <div class="text_msg">(5) Information that undermines China’s religious policies or promotes cults or superstitions;</div>
      <div class="text_msg">(6) Content spreading rumors, disturbing public order, or undermining social stability;</div>
      <div class="text_msg">(7) Information involving obscenity, pornography, gambling, violence, murder, terror, or incitement to crimes;</div>
      <div class="text_msg">(8) Content insulting or slandering others, or infringing upon the lawful rights of others;</div>
      <div class="text_msg">(9) False, fraudulent, harmful, coercive, invasive of privacy, harassing, defamatory, vulgar, obscene, or morally objectionable content;</div>
      <div class="text_msg">(10) Any other content restricted or prohibited by Chinese laws, regulations, rules, or any other legal norms;</div>
      <div class="text_msg">(11) Content that endangers the legitimate rights and interests of others (e.g., stealing bank accounts, engaging in false transactions, using technical means to create fake accounts in bulk);</div>
      <div class="text_msg">(12) Content that infringes on copyrights, patents, trademarks, trade secrets, or other proprietary rights;</div>
      <div class="text_msg">(13) Any information the Company reasonably believes is unrelated to platform transactions or disrupts the normal trading order;</div>
      <div class="text_msg">(14) Any other content deemed inappropriate by the Company, or that violates public interest, social order, good customs, or harms the legitimate rights of others.</div>
      <div class="text_msg">4.4 The user shall not engage in the following activities:</div>
      <div class="text_msg">(1) Deleting, modifying, or adding to computer network functions without permission;</div>
      <div class="text_msg">(2) Deleting, modifying, or adding to data or applications in computer networks without authorization;</div>
      <div class="text_msg">(3) Intentionally creating or spreading viruses or other harmful programs;</div>
      <div class="text_msg">(4) Any other activities that endanger the security of computer networks.</div>
      <div class="text_msg">4.5 The user shall not interfere with the platform’s services in any way, including, but not limited to, the development, support, or use of software, devices, scripts, or programs that damage or disrupt the normal operation of the platform.</div>
      <div class="text_msg">4.6 Unless otherwise specified in this Agreement, the user shall not engage in the following activities without the prior written consent of the Company:</div>
      <div class="text_msg">(1) Copying, decompiling, disassembling, distributing, or reverse engineering any part of the platform’s programs, user manuals, or audiovisual materials;</div>
      <div class="text_msg">(2) Publicly displaying or broadcasting any part of the platform’s services or transaction processes on various platforms (including but not limited to online streaming platforms, websites, or apps);</div>
      <div class="text_msg">(3) Modifying or obscuring the platform’s service names, logos, trademarks, copyrights, or other intellectual property declarations;</div>
      <div class="text_msg">(4) Violating the platform's "Robots" agreement or bypassing anti-crawling measures to illegally obtain information from the platform;</div>
      <div class="text_msg">(5) Any other actions that violate trademark law, copyright law, computer software protection regulations, or other relevant laws and international treaties.</div>

      <h3 class="title_big">Article 5: Platform Trading Rules</h3>
      <div class="text_msg">5.1 As an online trading platform, the platform enables users to conduct lawful transactions in compliance with applicable laws, this Agreement, and the platform's rules. The Company does not participate in transactions between the parties and is not obligated to review user registration data, transactions, or other transaction-related matters. However, the user agrees that, in order to protect the interests of the Company and other users, the Company reserves the right to monitor the user’s use of the software and services.</div>
      <div class="text_msg">If the user violates any laws, regulations, relevant policies, the terms of this Agreement, or the platform’s rules, or if a third party reports the user, or the Company detects abnormal account data or transactions, the Company reserves the right to take one or more of the following actions, without prior notice to the user, to eliminate or mitigate the impact of the misconduct:</div>
      <div class="ul_list" >
        <ul>
          <li>Restrict or prohibit the listing of goods</li>
          <li>Restrict or prohibit purchases</li>
          <li>Restrict or prohibit sending private messages</li>
          <li>Modify or delete the user’s username, avatar, or other customized materials</li>
          <li>Delete information posted by the user</li>
          <li>Restrict or prohibit platform login</li>
          <li>Suspend or terminate the provision of platform services</li>
          <li>Cancel or freeze orders</li>
          <li>Freeze or cancel transactions</li>
          <li>Restrict or prohibit platform access</li>
          <li>Restrict or revoke account rights</li>
          <li>Freeze or block accounts</li>
          <li>Notify third-party payment institutions to deduct equivalent transaction amounts from the user’s account balance</li>
          <li>Notify third-party payment institutions to restrict or prohibit the user’s use of their payment account and balance</li>
          <li>Publicize the user’s breach, violation, or misconduct on the platform.</li>
        </ul>
      </div>
      <div class="text_msg">In such cases, the user has no right to hold the Company responsible for any resulting consequences, and any legal liability or loss shall be borne solely by the user. The user’s legal liabilities and losses may include, but are not limited to, compensation to the Company and/or its affiliates for any losses suffered, including attorney fees, damages, government penalties, and other costs arising from the user’s misconduct.</div>

      <h3 class="title_big">Article 6: Ownership of Intellectual Property Rights and Other Rights</h3>
      <div class="text_msg">6.1 All works and materials on the platform, including but not limited to text, software, sound, photos, videos, charts, etc., owned by the Company are protected by copyright, patent rights, trademark rights, and other intellectual property rights. Unless expressly authorized by the Company in advance, no one may use these materials in any form without permission. If unauthorized use occurs, the Company reserves the right to immediately terminate the provision of services to the offending user and pursue legal action in accordance with the law.</div>
      <div class="text_msg">6.2 The user warrants that all content published on the platform has legal ownership or legal authorization, and that such content does not infringe the legitimate rights and interests of any third party. By submitting such content, the user irrevocably grants the Company and its affiliates a worldwide, perpetual, royalty-free license to use, transmit, reproduce, modify, sublicense, translate, publish, perform, display, and create derivative works based on this content.</div>
     

      <h3 class="title_big">Article 7: Privacy Policy</h3>
      <div class="text_msg">7.1 When the Company requests users to provide personal identity information, it will clearly disclose its privacy protection policies and personal information usage policies in an easily accessible manner and take necessary steps to protect the security of the user's personal information.</div>
      <div class="text_msg">Without the user's consent, the Company will not provide, disclose, or share personally identifiable information, such as name, valid identification number, contact information, home address, and other registration data, to any third party, except under the following circumstances:</div>
      <div class="text_msg">(1) The user or the user’s legal guardian authorizes the Company to disclose the information;</div>
      <div class="text_msg">(2) The Company is required to disclose the information by relevant laws;</div>
      <div class="text_msg">(3) Judicial or administrative authorities request the Company to provide the information through legal procedures;</div>
      <div class="text_msg">(4) The Company files a lawsuit or arbitration against the user to protect its legitimate rights and interests;</div>
      <div class="text_msg">(5) The user’s legal guardian requests the information through lawful means.</div>
      <div class="text_msg">To protect users' privacy and regulate the use of personal information, the Company has formulated a "Platform Privacy Policy," which users are encouraged to read thoroughly.</div>
      <div class="text_msg">7.2 In addition to the aforementioned personal information, users agree and authorize the Company to collect other necessary information as required by administrative or judicial authorities, or for security, user experience optimization, and other considerations. The Company may only collect and use this information for the purposes of executing this Agreement.</div>



      <h3 class="title_big">Article 8: Modification, Suspension, and Termination of Services</h3>
      <div class="text_msg">8.1 The Company reserves the right to modify or interrupt platform services at any time without prior notice to the user.</div>
      <div class="text_msg">8.2 To ensure the normal operation of the platform, the Company may periodically conduct maintenance or emergency shutdowns. Users are expected to understand that these actions may cause temporary service interruptions. The Company will make every effort to minimize service disruptions and limit downtime to the shortest possible duration.</div>
      <div class="text_msg">8.3 The Company may terminate platform services due to business needs after providing prior notice to the user. The user agrees that the Company will not be held responsible for any losses incurred due to the termination of services, provided the Company has notified the user in advance.</div>
      <div class="text_msg">8.4 The Company may terminate services to a specific user under the following circumstances:</div>
      <div class="text_msg">(1) The user violates any laws, regulations, provisions of this Agreement, or platform rules. If the user re-registers or registers using another person’s identity after termination, the Company reserves the right to terminate services again.</div>
      <div class="text_msg">(2) The user requests termination of services, and the Company agrees.</div>
      <div class="text_msg">(3) Other circumstances as outlined by the Agreement or by law.</div>
      <div class="text_msg">8.5 Platform operators may change as a result of business adjustments. Any such changes will be notified to users, and these changes will not affect users' rights under this Agreement.</div>
      

      <h3 class="title_big">Article 9: Limitation of Liability</h3>
      <div class="text_msg">9.1 The views, suggestions, and other content expressed by users on the platform represent the views of the users themselves and do not reflect the views of the Company.</div>
      <div class="text_msg">9.2 The Company is not liable for losses caused by force majeure. "Force majeure" as referred to in this Agreement includes natural disasters, changes in laws or regulations, government directives, reasons specific to third-party platforms, or network service factors (such as failures of telecommunications operators, internet-related technical defects, hardware/software failures, viruses, hacker attacks, and other uncontrollable circumstances).</div>
      <div class="text_msg">9.3 The user understands and agrees that platform services are provided on an "as-is" basis, in line with current technology and available conditions. The Company will do its best to provide consistent and secure services but cannot guarantee immunity from technical risks, such as system instability, third-party service defects, viruses, hacker attacks, or government actions that may lead to service interruptions, data loss, or other risks. The Company does not guarantee that any materials or data downloaded from the platform are virus-free or free from contamination or destruction.</div>
      <div class="text_msg">Due to the peculiarities of the network environment, the Company cannot ensure that the platform, its services, or functions will be free from errors or interruptions, or that defects will be corrected. If you are dissatisfied with the platform or its content, you may stop using the platform. The limitation of remedies is part of the Agreement between the parties.</div>
      <div class="text_msg">9.4 The Company shall not be liable for any inconvenience or damage caused by:</div>
      <div class="text_msg">(1) Regular maintenance or updates of the website, servers, or software/hardware;</div>
      <div class="text_msg">(2) Network service provider failures or other technical faults;</div>
      <div class="text_msg">(3) Measures taken in compliance with legal provisions or government directives;</div>
      <div class="text_msg">(4) Disputes between users and third parties;</div>
      <div class="text_msg">(5) Operations performed in compliance with legal documents or government mandates;</div>
      <div class="text_msg">(6) Any other circumstances beyond the Company’s control.</div>
  

      <h3 class="title_big">Article 10: Notification</h3>
      <div class="text_msg">All notices sent by the Company to users may be delivered via announcements, emails, or other methods such as telephone, SMS, WeChat, mail, or letters. Users also agree that the Company may send commercial information to their email, phone, WeChat, or mail.</div>
    

      <h3 class="title_big">Article 11: Other Agreements</h3>
      <div class="text_msg">11.1 If users have any questions about this Agreement, they may consult the platform for further information or contact customer service via email at wblfl12345678@gmail.com.</div>
      <div class="text_msg">11.2 Users acknowledge and agree that platform services may be subject to third-party restrictions, including those related to application development, operations, transactions, and payments. If any part of the platform service is deemed invalid due to third-party restrictions, both parties will refer to this Agreement for resolution. The Company will not be responsible for any losses, and the user agrees not to pursue legal action against the Company. If any part of this Agreement is determined to be fully or partially invalid due to legal provisions, third-party rules, or court rulings, the remaining provisions of the Agreement will remain in full force</div>
      <div class="text_msg">11.3 The user understands and agrees that the platform may send advertisements, promotions, or other information (both commercial and non-commercial) directly or through third-party advertisers during service provision. The manner and scope of such communications may change without special notice. The user is responsible for evaluating the authenticity of advertisements and assumes responsibility for their own decisions. The platform is not liable for any losses or damages resulting from transactions based on advertising or information provided by third-party advertisers, except as otherwise required by law.</div>
    


              
  </div>
</template>

<script>
export default {

}
</script>

<style lang='scss' scoped>
.ul_list{
  text-align: left;
  margin-left:20px;
  ul{
    list-style: disc;
    padding-left:20px;
  }
}
</style>